import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { CARRIER_RATE, CALLER_RATE } from '../../constants';
import {
    StyledContainer,
    InformationContainer,
    DetailsContainer,
    Title,
    Description,
    StyledPhoneIcon,
    CardRow,
    AmountText,
    InfoText,
    IconContainer,
} from './styled';

const ModalBody = ({ name, phone, type }) => {
    const price = type === 'carrier' ? CARRIER_RATE : CALLER_RATE;
    const infoText = type === 'carrier' ? 'This allows you to know the carriers and the type of line of the telephone numbers of the members of the group. This service will cost $0.008 for each number to be analyzed.' : 'This allows you to know the caller name and the caller type of the telephone numbers of the members of the group. This service will cost $0.02 for each number to be analyzed.';
    return (
        <>
            <InfoText>
                {infoText}
            </InfoText>
            <StyledContainer>
                <InformationContainer>
                    <IconContainer>
                        <StyledPhoneIcon />
                    </IconContainer>
                    <DetailsContainer>
                        <Title>{name}</Title>
                        <Description>{phone}</Description>
                    </DetailsContainer>
                </InformationContainer>
                <CardRow>
                    <Description>Cost</Description>
                    <AmountText>
                        <FormattedNumber
                            value={price}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency="USD"
                            maximumFractionDigits={3}
                        />
                    </AmountText>
                </CardRow>
            </StyledContainer>
        </>
    );
};

ModalBody.propTypes = {
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default ModalBody;

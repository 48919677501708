import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Download } from '@rainbow-modules/icons';
import { useIntl } from 'react-intl';
import { DownloadModal } from '@rainbow-modules/listview';
import { useParams } from 'react-router-dom';
import { ButtonGroup, Column } from 'react-rainbow-components';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import { ButtonIconTooltip } from '../../../components';
import FormatedDateCol from '../../../components/formatted-date-col';
import LeftColumn from '../../../components/left-column';
import { ReplyTable, ReplyContainer } from './styled';
import repliesQuery from './helpers/repliesQuery';
import repliesDownloadQuery from './helpers/repliesDownloadQuery';

const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

const Reply = ({ campaignDoc }) => {
    const campaign = campaignDoc.data;
    const { name } = campaign;
    const intl = useIntl();
    const { id } = useParams();
    const { uid } = getCurrentUser();
    const [isOpen, setIsOpen] = useState(false);

    const handleDownload = async ({ max }) => {
        const snapshot = await repliesDownloadQuery(uid, id, max);

        return snapshot.docs.map((doc) => {
            const {
                createdAt, from, to, body,
            } = doc.data();
            const formattedDate = intl.formatDate(createdAt.toDate(), options).replace(/,/g, '');
            return {
                Date: formattedDate,
                'Phone number': from,
                'Reply To': to,
                Message: body.replace(/,/g, ' '),
            };
        });
    };

    return (
        <>
            <ReplyContainer>
                <ButtonGroup
                    variant="shaded"
                >
                    <ButtonIconTooltip
                        variant="border-filled"
                        shaded
                        className="rainbow-m-left_medium"
                        icon={<Download />}
                        onClick={() => setIsOpen(true)}
                        text="Download Replies"
                    />
                </ButtonGroup>
            </ReplyContainer>
            <ReplyTable
                collection={`users/${uid}/messages`}
                query={(ref) => repliesQuery(ref, id)}
                variant="listview"
                pageSize={20}
            >
                <Column field="createdAt" header="Date" component={FormatedDateCol} width={200} />
                <Column field="from" header="Phone Number" width={200} />
                <Column field="to" header="Reply To" width={200} />
                <Column field="body" header="Message" component={LeftColumn} />
            </ReplyTable>
            <DownloadModal
                title="Download Replies"
                description={`Download the report of replies received from the members of campaign ${name}.`}
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                maxEntries={1000}
                onDownload={handleDownload}
                fileName="replies"
            />
        </>
    );
};
Reply.propTypes = {
    campaignDoc: PropTypes.object,
};

Reply.defaultProps = {
    campaignDoc: undefined,
};

export default Reply;

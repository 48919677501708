import styled from 'styled-components';

const MessageText = styled.h3`
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 999;
    margin-top: 120px;
    font-weight: 900;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export default MessageText;

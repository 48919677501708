import React, { useState } from 'react';
import {
    Input,
    Picklist,
    PicklistOption,
    MenuDivider,
} from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import propTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Form,
    TotalCost,
    Footer,
    Divider,
    Row,
    Column,
    Fields,
    Phone,
    Plus,
} from './styled';
import createCampaign from './createCampaign';
import { showSpinner, hideSpinner } from '../../../components/global-spinner';
import useGroups from '../../../hooks/useGroups';
import useCurrentCredit from '../../../hooks/useCurrentCredit';
import { showErrorMessage } from '../../../redux/actions/app';
import MessageTextarea from './textarea';
import useDefaultPhoneNumber from '../../../hooks/useDefaultPhoneNumber';
import getCost from './helpers/getCost';
import { getNormalizedBody } from '../../../helpers';

const UserGroupOptions = ({ groups }) => groups.map(
    (group, index) => (
        <PicklistOption
            key={`option-${index + 1}`}
            name={group.id}
            label={group.data.name}
            value={group.data} />
    ),
);

const CREATE_GROUP = 'create-group';
const STOP_TO_END_MESSAGE = '\nSTOP to end';

function NewCampaignForm(props) {
    const { handleSubmit, onResquestClose } = props;
    const dispatch = useDispatch();
    const [groups, isLoadingGroups] = useGroups();
    const [message, setMessage] = useState('');
    const [messageType, updateMessageType] = useState('sms');
    const [imageRef, setImageRef] = useState(undefined);
    const [selectedGroup, setSelectedGroup] = useState();
    const [isActiveStopToEnd, setIsActiveStopToEnd] = useState(false);
    const defaultPhoneNumber = useDefaultPhoneNumber();
    const messageText = getNormalizedBody(message, isActiveStopToEnd, STOP_TO_END_MESSAGE);

    const allMembers = (selectedGroup && selectedGroup.members) || 0;
    const unsubscribesMembers = (selectedGroup && selectedGroup.unsubscribes) || 0;
    const subscribedMembers = allMembers - unsubscribesMembers;
    const members = subscribedMembers >= 0 ? subscribedMembers : 0;
    const costPerMember = getCost(messageText, messageType);
    const cost = members > 0 ? (members * costPerMember).toFixed(2) : '0.00';
    const currentCredit = useCurrentCredit();
    const history = useHistory();

    let costError = '';
    if (typeof currentCredit === 'number') {
        costError = cost > currentCredit ? 'Insufficient credit' : '';
    }
    const onSubmit = async (values) => {
        const { name, group } = values;
        dispatch(showSpinner({
            message: 'Creating Campaign & Sending SMS',
        }));
        try {
            await createCampaign({
                name,
                groupId: group.name,
                body: messageText,
                type: messageType,
                imageUrl: imageRef ? await imageRef.getDownloadURL() : null,
            });
            showAppNotification({
                title: 'Campaign Send Successfully!',
                description: `The "${name}" campaign was sent successfully.`,
                icon: 'success',
                timeout: 5000,
            });
        } catch (err) {
            if (err.message === 'Insufficient credit.') {
                dispatch(showErrorMessage({
                    message: 'Uh-oh! We Were Unable To Process Yor Campaign. Insufficient credit.',
                }));
            } else {
                dispatch(showErrorMessage(err));
            }
        }
        onResquestClose();
        dispatch(hideSpinner());
    };

    const selectGroup = (group) => {
        if (group.name === CREATE_GROUP) {
            return history.push('/app/groups', { isDrawerOpen: true });
        }
        return setSelectedGroup(group.value);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate id="create-campaign">
            <Column>
                <Fields>
                    <Field
                        component={Input}
                        name="name"
                        label="Campaign Name"
                        placeholder="Enter the campaign name"
                        className="rainbow-m-bottom_large rainbow-m-top_medium"
                        required />
                    <Field
                        component={Picklist}
                        name="group"
                        label="To"
                        required
                        isLoading={isLoadingGroups}
                        placeholder="Choose Group"
                        onChange={selectGroup}
                        enableSearch
                        className="rainbow-m-bottom_large">
                        <PicklistOption
                            name={CREATE_GROUP}
                            label="Create Group"
                            icon={<Plus />}
                        />
                        <MenuDivider variant="space" />
                        <PicklistOption
                            label="Your Groups"
                            variant="header"
                        />
                        <UserGroupOptions groups={groups} />
                    </Field>
                    <Field
                        component={MessageTextarea}
                        name="body"
                        rows={5}
                        label="Message"
                        required
                        onChange={(event) => setMessage(
                            event.target.value,
                        )}
                        placeholder="Type your message"
                        onSelectType={(event) => updateMessageType(event.target.value)}
                        type={messageType}
                        message={messageText}
                        onSelectImage={(ref) => setImageRef(ref)}
                    />
                    <Row>
                        <Field
                            component={Input}
                            name="stop"
                            label={`Add '${STOP_TO_END_MESSAGE}' to message`}
                            type="checkbox"
                            className="rainbow-m-left_xx-small"
                            onChange={(event) => setIsActiveStopToEnd(event.target.checked)}
                        />
                    </Row>
                </Fields>
                <Footer>
                    <TotalCost
                        hiddeCurrency
                        size="small"
                        label="Members"
                        value={members} />
                    <Divider />
                    <TotalCost
                        label="Price Per Member"
                        size="small"
                        value={costPerMember} />
                    <Divider />
                    <TotalCost
                        error={costError}
                        label="Campaign Cost"
                        size="small"
                        value={cost} />
                </Footer>
            </Column>
            <Phone
                text={messageText}
                type={messageType}
                phoneNumber={defaultPhoneNumber}
                imageRef={imageRef}
            />
        </Form>
    );
}

NewCampaignForm.propTypes = {
    handleSubmit: propTypes.func.isRequired,
    onResquestClose: propTypes.func,
};

NewCampaignForm.defaultProps = {
    onResquestClose: () => {},
};

const validate = (values) => {
    const {
        name, group, body, type, stop,
    } = values;
    const errors = {};
    if (!name) {
        errors.name = 'Name of the campaign is a required field.';
    }
    if (!group) {
        errors.group = 'Please select a group.';
    }
    if (!body) {
        errors.body = 'Message is a required filed.';
    }
    if (type === 'sms' && getNormalizedBody(body, stop, STOP_TO_END_MESSAGE).length > 800) {
        errors.body = 'SMS must be less than 800 characters';
    }
    if (type === 'mms' && getNormalizedBody(body, stop, STOP_TO_END_MESSAGE).length > 1600) {
        errors.body = 'MMS must be less than 1600 characters';
    }
    return errors;
};

export default reduxForm({
    form: 'create-campaign',
    touchOnBlur: false,
    validate,
})(NewCampaignForm);

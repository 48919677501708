import React from 'react';
import PropTypes from 'prop-types';
import EmptyCell from '../emptyCell';

const CallerType = ({ value }) => {
    return (
        <EmptyCell value={value?.caller_type} />
    );
};

CallerType.propTypes = {
    value: PropTypes.object,
};

CallerType.defaultProps = {
    value: undefined,
};
export default CallerType;

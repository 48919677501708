import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    Button,
    Column,
    MenuItem,
    Drawer,
} from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { confirmModal, showAppNotification } from '@rainbow-modules/app';
import { showSpinner, hideSpinner } from '../../../components/global-spinner';
import { showErrorMessage } from '../../../redux/actions/app';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import NewGroupForm from './newGroupForm';
import {
    Container,
    GroupCard,
    GroupTable,
    Header,
    Title,
    TrashIcon,
} from './styled';
import FormattedDateCol from '../../../components/formatted-date-col';
import createGroup from './createGroup';
import removeGroup from '../../../services/removeGroup';
import useCurrentUser from '../../../hooks/useCurrentUser';
import GroupLink from './groupLink';
import { getFirestorePath } from '../../../helpers';
import LeftColumn from '../../../components/left-column';
import payGroupCreation from './payGroupCreation';

const groupExists = (groupName, groupsList) => groupsList.some(
    (group) => group.data.name.toLowerCase() === groupName.toLowerCase(),
);

function Groups({ location }) {
    const { state } = location;
    const dispatch = useDispatch();
    const isDrawerOpen = state && state.isDrawerOpen;
    const [isOpen, setIsOpen] = useState(isDrawerOpen);
    const [groups, isLoading] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/groups`),
        query: (ref) => ref.where('visible', '==', true),
    });

    const sortedGroups = groups.sort((groupA, groupB) => {
        const aDate = groupA.data.createdAt.toDate();
        const bDate = groupB.data.createdAt.toDate();
        return (bDate > aDate) - (aDate > bDate);
    });
    const { uid } = useCurrentUser();

    const closeNewGroupForm = () => setIsOpen(false);

    const create = async (values) => {
        const { name, contacts, checkInformation } = values;
        try {
            if (groupExists(name, groups)) {
                const shouldCreateGroup = await confirmModal({
                    header: `Group "${name}" already exists`,
                    question: 'You\'re creating a group with a duplicated name.',
                    okButtonLabel: 'Continue anyway',
                });
                if (shouldCreateGroup === false) return;
            }

            dispatch(showSpinner({
                message: 'Creating group.',
            }));
            const data = contacts.data.map(
                (contact) => ({ ...contact, lookupType: checkInformation }),
            );
            await createGroup({
                uid,
                name,
                contacts: data,
            });

            await payGroupCreation({
                name,
                contacts: data,
                lookupType: checkInformation,
            });

            closeNewGroupForm();
            showAppNotification({
                title: 'Success!',
                description: `The group "${name}" was created successfully.`,
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            showAppNotification({
                title: 'Error!',
                description: `There was an error. The group "${name}" was not created.`,
                icon: 'error',
                timeout: 5000,
            });
        }
        dispatch(hideSpinner());
    };

    const remove = async (_, record) => {
        const { id: groupId, data: { name: groupName } } = record;
        const shouldDelete = await confirmModal({
            icon: <TrashIcon />,
            variant: 'destructive',
            header: 'Delete group',
            question: `The group "${groupName}" will be deleted immediately. You can't undo this action.`,
            okButtonLabel: 'Delete',
        });
        if (shouldDelete) {
            dispatch(showSpinner({
                message: `Deleting group "${groupName}"`,
            }));
            try {
                await removeGroup(uid, groupId);
                showAppNotification({
                    title: 'Group Deleted Successfully!',
                    description: `The group "${groupName}" was deleted successfully.`,
                    icon: 'success',
                    timeout: 5000,
                });
            } catch (err) {
                dispatch(showErrorMessage({
                    title: 'Delete Group Failed!',
                    message: 'There was an error while deleting group',
                }));
            }
            dispatch(hideSpinner());
        }
    };
    return (
        <Container>
            <GroupCard>
                <Header>
                    <Title>Your Groups</Title>
                    <Button
                        variant="border-filled"
                        label="Create Group"
                        onClick={() => setIsOpen(true)}
                        shaded
                    />
                </Header>
                <GroupTable keyField="id" data={sortedGroups} isLoading={isLoading} variant="listview">
                    <Column header="Group Name" field="data.name" component={GroupLink} headerAlignment="left" />
                    <Column header="Members" field="data.members" width={140} component={LeftColumn} />
                    <Column header="Date" field="data.createdAt" width={180} component={FormattedDateCol} />
                    <Column type="action">
                        <MenuItem label="Delete" onClick={remove} />
                    </Column>
                </GroupTable>
            </GroupCard>
            <Drawer
                header="New Group"
                isOpen={isOpen}
                slideFrom="right"
                onRequestClose={closeNewGroupForm}>
                <NewGroupForm onSubmit={create} />
            </Drawer>
        </Container>
    );
}

Groups.propTypes = {
    location: PropTypes.object.isRequired,
};

export default Groups;

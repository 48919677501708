import React from 'react';
import PropTypes from 'prop-types';
import EmptyCell from '../emptyCell';

const CarrierName = ({ value }) => {
    return (
        <EmptyCell value={value?.name} />
    );
};

CarrierName.propTypes = {
    value: PropTypes.object,
};

CarrierName.defaultProps = {
    value: undefined,
};
export default CarrierName;

import firebase from '../../../../firebase';
import { getFirestorePath } from '../../../../helpers';
import repliesQuery from './repliesQuery';

const repliesDownloadQuery = async (uid, id, max) => {
    const ref = firebase.firestore().collection(getFirestorePath(`users/${uid}/messages`));
    return repliesQuery(ref, id).limit(max).get();
};

export default repliesDownloadQuery;

import firebase from '../firebase';
import { getFirestorePath } from '../helpers';

const removeGroup = async (uid, groupId, name) => {
    await firebase.firestore().doc(getFirestorePath(`users/${uid}/groups/${groupId}`)).update({
        visible: false,
    });
    return { name };
};

export default removeGroup;

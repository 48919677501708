import { useCollection } from '@rainbow-modules/firebase-hooks';

const useContacts = (props) => {
    const {
        uid, groupId, phone,
    } = props;

    const query = (ref) => {
        const queryLimit = (ref) => ref.limit(100);
        const queryPhone = phone ? ((ref) => ref.where('phone', '==', phone)) : (ref) => ref;
        const finalQuery = queryLimit(queryPhone(ref));
        return finalQuery;
    };

    const [data, isLoading] = useCollection({
        path: `users/${uid}/groups/${groupId}/contacts`,
        query,
        track: [uid, groupId, phone],
    });

    return [data, isLoading];
};

export default useContacts;

import React from 'react';
import PropTypes from 'prop-types';
import { StyledEmptyText } from './styled';

const EmptyCell = ({ value }) => {
    const newValue = value || 'UNKNOWN';
    return (
        <StyledEmptyText value={newValue}>
            {newValue}
        </StyledEmptyText>
    );
};

EmptyCell.propTypes = {
    value: PropTypes.string,
};
EmptyCell.defaultProps = { value: undefined };

export default EmptyCell;

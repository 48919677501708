import React from 'react';
import { EvertransitApp } from '@nexxtway/react-evertransit';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { useAuthState } from '@rainbow-modules/firebase-hooks';
import { Switch, Redirect } from 'react-router-dom';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { LogoIcon } from './components/initializing-app/styled';
import RenderIfStandaloneMode from './components/render-if-standalone-mode';
import RenderIfEtMode from './components/render-if-et-mode';
import AuthRoutes from './pages/auth';
import PublicRoutes from './pages/public';
import reducers from './redux/reducers';
import translations from './i18n/translations.json';
import firebase from './firebase';
import { AuthRoute } from './components';
import PublicRoute from './components/public-route';
import SpinnerMessage from './components/spinner-message';

// eslint-disable-next-line react/prop-types
function InitializingApp({ message }) {
    return (
        <>
            <Spinner type="arc" variant="brand" size="x-large">
                <LogoIcon />
            </Spinner>
            <RenderIf isTrue={!!message}>
                <SpinnerMessage message={message} />
            </RenderIf>
        </>
    );
}

const RoutesStandaloneMode = () => {
    const isAuth = useAuthState();
    const rootRedirect = isAuth ? '/app' : '/home';
    if (isAuth === null || isAuth === undefined) return null;

    return (
        <Switch>
            <Redirect from="/" exact to={rootRedirect} />
            <PublicRoute path="/home" component={PublicRoutes} isAuth={isAuth} />
            <AuthRoute path="/app" component={AuthRoutes} isAuth={isAuth} />
        </Switch>
    );
};

const theme = process.env.REACT_APP_NAME === 'Landslide Reach'
    ? {
        rainbow: {
            palette: {
                brand: '#7a53fe',
            },
        },
    } : undefined;

export default function App() {
    return (
        <>
            <RenderIfStandaloneMode>
                <RainbowFirebaseApp
                    app={firebase}
                    locale="en"
                    spinner={<InitializingApp />}
                    reducers={reducers}
                    translations={translations}
                    theme={theme}
                >
                    <RoutesStandaloneMode />
                </RainbowFirebaseApp>
            </RenderIfStandaloneMode>
            <RenderIfEtMode>
                <EvertransitApp app={firebase} reducers={reducers} translations={translations}>
                    <AuthRoutes />
                </EvertransitApp>
            </RenderIfEtMode>
        </>
    );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { RecordHeader, RecordPrimaryDetails, RecordField } from '@rainbow-modules/record';
import TextOutput from '../../../components/text-output';
import BackLink from '../../../components/back-link';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import CampaingnTabset from './tabset';
import { getFirestorePath } from '../../../helpers';
import Delivery from './delivery';
import Reply from './reply';
import {
    Container,
    Content,
    StyledLink,
    CampaignDetailsCard,
    StyledCampaignIcon,
} from './styled';

// eslint-disable-next-line react/prop-types
const GroupLink = ({ value, url, campaignId }) => {
    return (
        <StyledLink
            to={{
                pathname: url,
                state: { campaignId },
            }}
        >
            {value}
        </StyledLink>
    );
};

const tabContentMap = {
    delivery: Delivery,
    reply: Reply,
};

export default function CampaignDetails({ className }) {
    const { id } = useParams();
    const [selected, setSelected] = useState('delivery');
    const TabContent = tabContentMap[selected];
    const [campaignDoc, isLoading] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/campaigns/${id}`),
    });

    const handleOnSelect = (event, value) => {
        setSelected(value);
    };
    if (isLoading || !campaignDoc) {
        return <Spinner />;
    }

    const campaign = campaignDoc.data;
    const {
        name,
        createdAt,
        body,
        cost,
        incomingMessagesCost = 0,
        group: {
            id: groupId,
            members = 0,
            name: groupName = 'unknown',
        },
    } = campaign;

    const smsSend = members;

    return (
        <Container className={className}>
            <BackLink to="/app/campaigns" label="Back to Campaigns" />
            <Content>
                <CampaignDetailsCard>
                    <RecordHeader label="CAMPAIGNS" description={name} icon={<StyledCampaignIcon />}>
                        <RecordPrimaryDetails>
                            <RecordField label="Date & Time" value={createdAt.toDate()} type="dateTime" />
                            <RecordField label="Contacts" value={members} />
                            <RecordField
                                label="To"
                                type="url"
                                value={groupName}
                                component={GroupLink}
                                url={`/app/groups/${groupId}`}
                                campaignId={id}
                            />
                            <RecordField label="SMS Send" value={smsSend} type="number" />
                            <RecordField label="Sent Campaign" value={cost} type="currency" />
                            <RecordField label="Replies" value={incomingMessagesCost} type="currency" />
                            <RecordField label="Total Cost" value={cost + incomingMessagesCost} type="currency" />
                        </RecordPrimaryDetails>
                        <TextOutput
                            className="rainbow-m-top_large"
                            label="Message"
                            value={body} />
                    </RecordHeader>
                </CampaignDetailsCard>
            </Content>
            <CampaingnTabset
                onSelect={handleOnSelect}
                activeTabName={selected} />
            <TabContent campaignDoc={campaignDoc} />
        </Container>
    );
}

CampaignDetails.propTypes = {
    className: PropTypes.string,
};

CampaignDetails.defaultProps = {
    className: undefined,
};

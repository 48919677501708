import React from 'react';
import PropTypes from 'prop-types';
import EmptyCell from '../emptyCell';

const CarrierType = ({ value }) => {
    return (
        <EmptyCell value={value?.type} />
    );
};

CarrierType.propTypes = {
    value: PropTypes.object,
};

CarrierType.defaultProps = {
    value: undefined,
};
export default CarrierType;

import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    CreditContainer,
    Label,
    ValueContainer,
    ValueLarge,
    Currency,
    ErrorContainer,
    ErrorIcon,
    ErrorText,
} from './styled';
import { round } from '../../helpers';

function Price(props) {
    const {
        className,
        style,
        label,
        value,
        size,
        error,
        hiddeCurrency,
        precision,
    } = props;
    const hasValue = typeof value === 'number' || (value && typeof value === 'string');

    const priceLabel = hasValue ? round(Number(value), precision) : '';

    return (
        <CreditContainer className={className} style={style}>
            <Label size={size}>{label}</Label>
            <ValueContainer>
                <RenderIf isTrue={!hiddeCurrency && hasValue}>
                    <Currency size={size}>$</Currency>
                </RenderIf>
                <ValueLarge size={size}>{priceLabel}</ValueLarge>
            </ValueContainer>
            <RenderIf isTrue={!!error}>
                <ErrorContainer>
                    <ErrorIcon />
                    <ErrorText>
                        {error}
                    </ErrorText>
                </ErrorContainer>
            </RenderIf>
        </CreditContainer>
    );
}

Price.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(['x-small', 'small', 'medium']),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hiddeCurrency: PropTypes.bool,
    precision: PropTypes.number,
};

Price.defaultProps = {
    className: undefined,
    style: {},
    label: undefined,
    value: undefined,
    size: 'medium',
    error: null,
    hiddeCurrency: false,
    precision: 2,
};

export default Price;

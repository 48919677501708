import styled from 'styled-components';
import { Phone } from '@rainbow-modules/icons';

export const InfoText = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 20px;
`;

export const StyledContainer = styled.div`
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 10px;
`;

export const InformationContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed
        ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    min-width: 0;
`;

export const Title = styled.h2`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Description = styled.h3`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const IconContainer = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 5px;
    padding: 10px;
`;

export const StyledPhoneIcon = styled(Phone)`
    width: 30px;
    height: 30px;
`;

export const CardRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
`;

export const AmountText = styled.p`
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

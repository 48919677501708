import React from 'react';
import PropTypes from 'prop-types';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { ColoredStatusColumn } from '@rainbow-modules/listview';
import { Column } from 'react-rainbow-components';
import LeftColumn from '../../../components/left-column';
import { CampaignTable } from './styled';
import { getFirestorePath } from '../../../helpers';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import getLabelFromStatus from './helpers/getLabelFromStatus';

const CustomColoredStatusColumn = (props) => {
    // eslint-disable-next-line react/prop-types
    const { value, ...rest } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ColoredStatusColumn value={getLabelFromStatus(value)} {...rest} />;
};

const buildQuery = (campaignId, limit) => {
    return (refs) => refs
        .where('campaignId', '==', campaignId)
        .where('type', '==', 'outgoing')
        .orderBy('createdAt', 'desc').limit(limit);
};

const shouldEnableFilters = (filters) => filters.length > 0 && filters.length < 5;

export default function CampaignMessages({ statusColors, campaignId, filters }) {
    const [messages, isLoading] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/messages`),
        query: buildQuery(campaignId, 50),
    });

    const filteredMessages = shouldEnableFilters(filters)
        ? messages.filter((message) => {
            return filters.includes(getLabelFromStatus(message.data.status));
        })
        : messages;

    return (
        <CampaignTable keyField="id" variant="listview" isLoading={isLoading} data={filteredMessages}>
            <Column
                header="Phone Number"
                field="data.to"
                defaultWidth={300}
                component={LeftColumn}
            />
            <Column header="Name" field="data.name" component={LeftColumn} />
            <Column
                header="Status"
                field="data.status"
                defaultWidth={200}
                colors={statusColors}
                component={CustomColoredStatusColumn}
            />
        </CampaignTable>
    );
}

CampaignMessages.propTypes = {
    statusColors: PropTypes.object,
    campaignId: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string),
};

CampaignMessages.defaultProps = {
    statusColors: {},
    campaignId: undefined,
    filters: [],
};

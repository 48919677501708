import styled from 'styled-components';
import {
    Table,
    Card,
} from 'react-rainbow-components';
import { UsersFilled, Trash, UserFilled } from '@rainbow-modules/icons';
import { InputSearch } from '@rainbow-modules/search';

export const Container = styled.section`
    height: 100%;
    overflow: auto;
`;

export const Content = styled.div`
    display: flex;
    margin: 0 25px;
`;

export const QuestionTitle = styled.h3`
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;
`;

export const StyledUserIcon = styled(UserFilled)`
    width: 18px;
    height: 18px;
`;

export const StyledIcon = styled(UsersFilled)`
    width: 20px;
    height: 20px;
`;

export const StyledCard = styled(Card)`
    width: 100%;
    padding: 0 20px;
`;

export const TrashIcon = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const StyledInputSearch = styled(InputSearch)`
    width: 400px;
    margin: 32px auto;
    position: relative;
`;

export const Members = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    margin: 0 20px;
`;

export const Title = styled.h1`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 32px 0 36px;
`;

export const MembersTable = styled(Table)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: ${(props) => props.theme.rainbow.palette.background.disabled};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Actions = styled.div`
    display: flex;
`;

export const StyledUnsubscribes = styled.span`
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const StyledEmptyText = styled.span`
    ${(props) => (props.value === 'UNKNOWN'
        ? `
            color: ${props.theme.rainbow.palette.text.header};
            font-style: italic;
        ` : `
            color: ${props.theme.rainbow.palette.text.main};
        `
    )};
`;

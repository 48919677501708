/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import {
    Table,
    Drawer as DrawerRainbowComp,
    ButtonIcon,
} from 'react-rainbow-components';
import { Picture } from '@rainbow-modules/icons';
import Price from '../../../components/price';
import MessagePhone from '../../../components/message-phone';
import PlusCircle from '../../../components/icons/plusCircle';

function getTheme(props) {
    return props.theme.rainbow;
}

function attachThemeAttrs(styledElement) {
    return styledElement.attrs((props) => {
        const theme = getTheme(props);
        return {
            ...props,
            ...theme,
        };
    });
}

export const Container = styled.section`
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`;

export const Link = styled.a`
    font-size: 20px;
`;

export const CampaignCard = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px 16px 32px;
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const CampaignTable = styled(Table)`
    box-sizing: border-box;
    height: auto;
    overflow: auto;
    margin: 0 20px 0;
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
`;

export const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 20px;
`;

export const Row = styled.div`
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
`;

export const MessageCounter = styled.h1`
    font-size: 14px;
    color: #a4a7b5;
    text-align: end;
`;

export const TotalCost = styled(Price)`
    align-items: center;
    justify-content: center;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

export const Divider = styled.div`
    height: 80%;
    width: 1px;
    background-color: #dcdcdc;
    margin-top: 4px;
`;

export const Drawer = styled(DrawerRainbowComp)`
    width: min-content;
`;

export const Fields = styled.div`
    min-width: 400px;
    margin-right: 20px;
    overflow: scroll;
`;

export const Phone = styled(MessagePhone)`
    margin: 0 8px 8px 4px;
    max-height: 620px;
`;

export const Plus = styled(PlusCircle)`
    margin-bottom: 2px;
`;

export const TextareaFooter = styled.div`
    background-color: rgba(227, 229, 237, 0.21);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    height: 48px;
    width: 100%;
`;

export const TextContainer = styled.div`
    display: flex;
`;

export const TextCount = styled.p`
    font-size: 14px;
    color: #a4a7b5;
    margin-right: 8px;
`;

export const MessageCount = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #a4a7b5;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 480px;
`;

export const Description = styled.p`
    font-size: 14px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    line-height: 1.5;
`;

export const PictureIcon = styled(Picture)`
    width: 20px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const PictureButtonIcon = styled(ButtonIcon)`
    width: 30px;
    height: 30px;

    > svg {
        width: 16px !important;
        height: 16px !important;
    }
`;

export const StyledLeftContent = styled.div`
    text-align: left;
    margin: 0 16px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

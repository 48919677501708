import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Download } from '@rainbow-modules/icons';
import { Tile } from '@rainbow-modules/dashboard';
import { useMutationFlow } from '@rainbow-modules/hooks';
import { ButtonGroup } from 'react-rainbow-components';
import ButtonIconTooltip from '../../../components/button-icon-tooltip';
import SMSStatus from './sms-status';
import CampaignMessages from './campaignMessages';
import {
    StyledContainer,
    TileContainer,
    Title,
    StatusContainer,
} from './styled';
import { getHash, createDownloadLink } from '../../../helpers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import getStatusFromLabel from './helpers/getStatusFromLabel';
import getCampaignReportUri from './getCampaignReportUri';

const statusColors = {
    'opted out': { backgroundColor: '#f2707a', color: '#fff' },
    delivered: { backgroundColor: '#9DC35E', color: '#fff' },
    queued: { backgroundColor: '#F4D879', color: '#fff' },
    bounced: { backgroundColor: '#F7A753', color: '#fff' },
    sent: { backgroundColor: '#cad5d5', color: '#fff' },
    // TODO: Add received status color
};

const getHoursBetweenDates = (date1, date2) => Math.floor(Math.abs(date1 - date2) / 3600000);

const getReportFileName = (campaignId, filters) => {
    const filtersStr = (filters.sort((filter1, filter2) => filter1 > filter2).join(','));
    const hash = getHash(`${campaignId}__[${filtersStr}]`);
    return `campaign-report-${hash}.csv`;
};

const Delivery = ({ campaignDoc }) => {
    const campaign = campaignDoc.data;
    const {
        name,
        createdAt,
        sent = 0,
        delivered = 0,
        undelivered = 0,
        failed = 0,
        group: {
            members: smsSend,
        },
    } = campaign;

    const finalFailed = failed + undelivered;
    const finalQueued = smsSend - (sent + failed);

    const { id } = useParams();
    const { uid } = useCurrentUser();
    const [activeFilters, setActiveFilters] = useState([]);

    const disableReportDownload = getHoursBetweenDates(createdAt.toDate(), new Date()) < 24;

    const downloadCampaignReport = async ({ id, uid, activeFilters }) => {
        const statuses = activeFilters.reduce((result, filter) => [
            ...result,
            ...getStatusFromLabel(filter),
        ], []);
        const outgoingFilter = [{ key: 'type', value: 'outgoing' }];
        const filters = statuses.length > 0
            ? [{ key: 'status', value: [...statuses] }, ...outgoingFilter]
            : outgoingFilter;
        const url = await getCampaignReportUri(
            getReportFileName(id, activeFilters),
            {
                uid,
                campaignId: id,
                filters,
            },
        );
        createDownloadLink(url, `campaign-report_${Date.now()}.csv`);
    };

    const { mutate } = useMutationFlow({
        mutation: (config) => downloadCampaignReport(config),
        submitSpinnerMessage: 'Downloading campaign report',
        successMessage: `The report for campaign "${name}" was downloaded successfully.`,
        errorMessage: `There was an error while downloading campaign "${name}" report.`,
    });

    return (
        <>
            <StyledContainer>
                <Title>SMS Status</Title>
                <ButtonGroup variant="shaded">
                    <ButtonIconTooltip
                        variant="border-filled"
                        shaded
                        className="rainbow-m-left_medium"
                        icon={<Download />}
                        text="Download Report"
                        disabled={disableReportDownload}
                        onClick={() => mutate({ id, uid, activeFilters })}
                    />
                </ButtonGroup>
            </StyledContainer>
            <StatusContainer>
                <SMSStatus
                    total={smsSend}
                    delivered={delivered}
                    queued={finalQueued}
                    bounced={finalFailed}
                    optedOut={0}
                    colors={statusColors}
                />
            </StatusContainer>
            <StyledContainer>
                <Title>Members</Title>
                <TileContainer multiple value={activeFilters} onChange={setActiveFilters}>
                    <Tile
                        name="sent"
                        label="Sent"
                        value={sent}
                        variant="badge"
                        backgroundColor="#cad5d5"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="delivered"
                        label="Delivered"
                        value={delivered}
                        variant="badge"
                        backgroundColor="#9DC35E"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="bounced"
                        label="Bounced"
                        value={finalFailed}
                        variant="badge"
                        backgroundColor="#F7A753"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="queued"
                        label="Queued"
                        value={finalQueued}
                        variant="badge"
                        backgroundColor="#F4D879"
                        color="#fff"
                        className="rainbow-m-right_medium"
                    />
                    <Tile
                        name="opted-out"
                        label="Opted Out"
                        value={0}
                        variant="badge"
                        backgroundColor="#F2707A"
                        color="#fff"
                    />
                </TileContainer>
            </StyledContainer>
            <CampaignMessages
                campaignId={id}
                statusColors={statusColors}
                filters={activeFilters}
            />
        </>
    );
};

Delivery.propTypes = {
    campaignDoc: PropTypes.object,
};

Delivery.defaultProps = {
    campaignDoc: undefined,
};

export default Delivery;

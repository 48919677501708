import React from 'react';
import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';
import { TabsetContainer } from './styled';

const CampaignTabset = ({ activeTabName, onSelect }) => (
    <TabsetContainer>
        <Tabset
            variant="line"
            onSelect={onSelect}
            activeTabName={activeTabName}
        >
            <Tab name="delivery" label="Delivery Statistics" />
            <Tab name="reply" label="Reply SMS" />
        </Tabset>
    </TabsetContainer>
);

CampaignTabset.propTypes = {
    activeTabName: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default CampaignTabset;

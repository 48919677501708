import React from 'react';
import PropTypes from 'prop-types';
import EmptyCell from '../emptyCell';

const CallerName = ({ value }) => {
    return (
        <EmptyCell value={value?.caller_name} />
    );
};

CallerName.propTypes = {
    value: PropTypes.object,
};

CallerName.defaultProps = {
    value: undefined,
};
export default CallerName;

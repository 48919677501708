import styled from 'styled-components';
import {
    Button, Table, Input, HelpText,
} from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import CsvFileIcon from '../../../components/icons/csv';
import ErrorCircleIcon from '../../../components/icons/errorCircle';
import InfoCircleIcon from '../../../components/icons/infoCircle';

export const Container = styled.section`
    width: 100%;
    padding: 8px 0;
    display: flex;
    height: 100%;
    overflow: auto;
`;

export const GroupCard = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 20px 0 20px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 16px;
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const GroupTable = styled(Table)`
    box-sizing: border-box;
    height: auto;
    overflow: auto;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const ImportContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ImportLabel = styled.h1`
    font-size: 14px;
    margin-bottom: 2px;
`;

export const InputName = styled(Input)`
    width: 100%;
`;

export const ImportContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    border: 1px solid rgba(227, 229, 237, 0.25);
    width: 100%;
    border-radius: 22px;
    padding: 2px;
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: center;
`;

export const ImportText = styled.h2`
    font-size: 16px;
    color: rgba(63, 73, 84, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ImportButton = styled(Button)`
    flex-shrink: 0;
`;

export const ImportIcon = styled(CsvFileIcon)`
    width: 28px;
    height: 28px;
    margin: 0 8px;
`;

export const ImportedContent = styled(ImportContent)`
    padding: 8px 0;
    border-radius: 14px;
`;

export const ImportIconLarge = styled(ImportIcon)`
    width: 42px;
    height: 42px;
`;

export const CreateButton = styled(Button)`
    margin-top: 15px;
    align-self: flex-end;
`;

export const ImportHelpText = styled.h4`
    font-size: 12px;
    color: #929ba5;
    text-align: center;
    margin-top: 4px;
`;

export const ImportDescription = styled.h3`
    font-size: 14px;
    color: #a4a7b5;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
`;

export const StatusIcon = styled(ErrorCircleIcon)`
    width: 12px;
    height: 12px;
    margin-right: 4px;
`;

export const StatusText = styled.h5`
    font-size: 12px;
    color: #929ba5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const InfoContainer = styled.div`
    border-radius: 20px;
    padding: 8px 8px 8px 12px;
    display: flex;
    background-color: #f8f9fa;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
`;

export const InfoIcon = styled(InfoCircleIcon)`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

export const InfoText = styled.h1`
    font-size: 14px;
    margin-right: 8px;
`;

export const TrashIcon = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: ${(props) => (props.showPaymentSummary ? 'space-between' : 'flex-end')};
    align-items: center;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    padding-top: 10px;
    width: 100%;
`;

export const PaymentSummaryContainer = styled.div`
    background-color: #f8f9fa;
    border-radius: 12px;
    width: 100%;
    margin: 12px 0;
`;

export const SummaryHeader = styled.h3`
    font-size: 18px;
    padding: 16px 16px;
    border-bottom: 1px dotted ${(props) => props.theme.rainbow.palette.border.disabled};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 16px;
`;

export const SummaryFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    margin: 8px 16px 0 16px;
    border-top: 1px dotted ${(props) => props.theme.rainbow.palette.border.disabled};
    padding: 12px 0;
`;

export const Total = styled.h4`
    font-size: 13px;
    text-transform: uppercase;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const TotalValue = styled.h3`
    font-size: 14px;
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    padding: 6px 0;
`;

export const CellContainer = styled.div`
    width: 33%;
`;

export const Service = styled.div`
    width: 50%;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 4px;
`;

export const Currency = styled.div`
    width: 25%;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 12px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: end;
    padding-left: 4px;
`;

export const ItemHeaderRightAlignment = styled.h6`
    width: 25%;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 13px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: end;
    padding-left: 4px;
`;

export const ItemHeaderLeftAlignment = styled.h6`
    width: 50%;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 13px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 4px;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 5px;
    width: 14px;
    height: 14px;
    display: inline-flex;

    > svg {
        margin-bottom: 3px;
        width: 14px;
        height: 14px;
    }
`;

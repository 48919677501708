const resolveLookupType = ({ isCarrierChecked, isCallerChecked, type }) => {
    if (!isCarrierChecked && !isCallerChecked) {
        return { lookupType: [type] };
    }
    if (type === 'carrier') return { lookupType: ['caller-name', type] };

    return { lookupType: ['carrier', type] };
};

export default resolveLookupType;

import React, { useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Trash } from '@rainbow-modules/icons';
import {
    Column,
    Badge,
    Spinner,
    ButtonIcon,
} from 'react-rainbow-components';
import { useMutationFlow } from '@rainbow-modules/hooks';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { RecordHeader, RecordPrimaryDetails, RecordField } from '@rainbow-modules/record';
import { confirmModal } from '@rainbow-modules/app';
import {
    Container,
    Content,
    Members,
    MembersTable,
    Actions,
    StyledContainer,
    StyledUnsubscribes,
    Title,
    StyledCard,
    StyledIcon,
    TrashIcon,
    StyledInputSearch,
} from './styled';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import useContacts from '../../../hooks/useContacts';
import { getFirestorePath } from '../../../helpers';
import BackLink from '../../../components/back-link';
import LeftColumn from '../../../components/left-column';
import ButtonActions from './columns/buttonActions';
import CarrierName from './columns/carrierName';
import CarrierType from './columns/carrierType';
import CallerName from './columns/callerName';
import CallerType from './columns/callerType';
import removeGroup from '../../../services/removeGroup';

// eslint-disable-next-line react/prop-types
const StatusBadge = ({ value }) => {
    if (value) {
        return <Badge label="unsubscribed" />;
    }
    return null;
};

const GroupDetails = () => {
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation();
    const { uid } = getCurrentUser();
    const campaignId = location.state && location.state.campaignId;
    const [phoneSearch, setPhoneSearch] = useState();
    const [contacts, isLoadingContacts] = useContacts({
        uid,
        groupId: id,
        phone: phoneSearch,
    });

    const [groupDoc, isLoadingGroup] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/groups/${id}`),
    });

    const { members = 0, name, unsubscribes = 0 } = groupDoc?.data || {};

    const labelBackTo = campaignId ? 'Back to Campaigns' : 'Back to Groups';
    const backToUrl = campaignId ? `/app/campaigns/${campaignId}` : '/app/groups';

    const { mutate } = useMutationFlow({
        mutation: ({ uid, id, name }) => removeGroup(uid, id, name),
        submitSpinnerMessage: 'Deleting Group',
        successMessage: ({ name }) => `The group "${name}" was deleted successfully.`,
        errorMessage: ({ name }) => `There was an error while deleting group "${name}".`,
    });

    const remove = async () => {
        const shouldDelete = await confirmModal({
            icon: <TrashIcon />,
            variant: 'destructive',
            header: 'Delete group',
            question: `The group "${name}" will be deleted immediately. You can't undo this action.`,
            okButtonLabel: 'Delete',
        });
        if (shouldDelete) {
            mutate({ uid, id, name });
            history.goBack();
        }
    };

    if (isLoadingGroup || !groupDoc || !groupDoc?.data) {
        return <Spinner />;
    }

    // eslint-disable-next-line react/prop-types
    const ButtonActionsComponent = ({ value, row }) => {
        return <ButtonActions value={value} row={row} members={members} />;
    };

    return (
        <Container>
            <BackLink to={backToUrl} label={labelBackTo} />
            <Content>
                <StyledCard>
                    <RecordHeader label="GROUP"
                        description={name}
                        icon={<StyledIcon />}
                        actions={(
                            <ButtonIcon
                                variant="border-filled"
                                icon={<Trash />}
                                onClick={remove}
                            />
                        )}>
                        <RecordPrimaryDetails>
                            <RecordField label="Total Members" value={members || 0} />
                            <RecordField label="Unsubscribes"
                                value={(
                                    <StyledUnsubscribes>
                                        {unsubscribes}
                                    </StyledUnsubscribes>
                                )} />
                        </RecordPrimaryDetails>
                    </RecordHeader>
                </StyledCard>
            </Content>
            <StyledContainer>
                <Title>
                    Members
                </Title>
                <Actions>
                    <StyledInputSearch placeholder="Find Member by phone number" onSearch={setPhoneSearch} />
                </Actions>
            </StyledContainer>
            <Members>
                <MembersTable
                    keyField="id"
                    data={contacts}
                    isLoading={isLoadingContacts}
                    variant="listview">
                    <Column header="Phone number" field="data.phone" component={LeftColumn} defaultWidth={160} />
                    <Column header="Name" field="data.name" component={LeftColumn} width={240} />
                    <Column header="Carrier" field="data.lookupResponse.carrier" defaultWidth={180} component={CarrierName} />
                    <Column header="Line Type" field="data.lookupResponse.carrier" defaultWidth={180} component={CarrierType} />
                    <Column header="Caller Name" field="data.lookupResponse.callerName" defaultWidth={200} component={CallerName} />
                    <Column header="Caller Type" field="data.lookupResponse.callerName" defaultWidth={200} component={CallerType} />
                    <Column header="Tags" field="data.unsubscribe" component={StatusBadge} />
                    <Column field="data.lookupResponse" component={ButtonActionsComponent} defaultWidth={50} />
                </MembersTable>
            </Members>
        </Container>
    );
};

export default GroupDetails;

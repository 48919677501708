/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import { useMutationFlow } from '@rainbow-modules/hooks';
import { useCurrentUser, useSetDoc, useRemoveDoc } from '@rainbow-modules/firebase-hooks';
import { confirmModal } from '@rainbow-modules/app';
import { More, TrashFilled, Wireless } from '@rainbow-modules/icons';
import resolveLookupType from '../helpers/resolveLookupType';
import ModalBody from '../modalBody';
import payAddLookupInfo from '../payAddLookupInfo';
import removeGroup from '../../../../services/removeGroup';
import { StyledUserIcon, TrashIcon, QuestionTitle } from '../styled';

const RemoveQuestion = ({ name }) => {
    return (
        <div>
            <QuestionTitle>
                Are you sure you want to delete the
                {' '}
                {name}
                {' '}
                contact?
            </QuestionTitle>
            This contact will be deleted immediately. You can&apos;t undo this action.
        </div>
    );
};

const MenuActions = ({
    row, isCarrierChecked, isCallerChecked, members,
}) => {
    const {
        id: docId,
        data: { name, phone },
    } = row;
    const history = useHistory();
    const { id: groupId } = useParams();
    const { uid } = useCurrentUser();
    const { mutate } = useSetDoc();
    const { mutate: removeMutate } = useRemoveDoc();

    const setContactInfo = async ({
        uid, groupId, docId, data, phone, type,
    }) => {
        await mutate({
            path: `/users/${uid}/groups/${groupId}/contacts/${docId}`,
            data,
            options: { merge: true },
        });
        await payAddLookupInfo({
            phone,
            lookupType: type,
        });
    };

    const removeContactInfo = async ({ uid, groupId, docId }) => {
        const newMembers = members - 1;
        await mutate({
            path: `/users/${uid}/groups/${groupId}`,
            data: { members: newMembers },
            options: { merge: true },
        });
        await removeMutate(`/users/${uid}/groups/${groupId}/contacts/${docId}`);
        if (newMembers === 0) {
            await removeGroup(uid, groupId);
            history.goBack();
        }
    };

    const { mutate: setMutation } = useMutationFlow({
        mutation: ({
            uid, groupId, docId, data, phone, type,
        }) => setContactInfo({
            uid, groupId, docId, data, phone, type,
        }),
        submitSpinnerMessage: 'Adding information.',
        successMessage: 'The information was added successfully.',
        errorMessage: 'There was an error. The information was not added.',
    });

    const { mutate: removeMutation } = useMutationFlow({
        mutation: removeContactInfo,
        submitSpinnerMessage: 'Removing contact.',
        successMessage: 'The contact was deleted successfully.',
        errorMessage: 'There was an error. The contact was not deleted.',
    });

    const handleClick = async (type) => {
        const modalHeader = type === 'carrier'
            ? 'Find Carrier Information'
            : 'Find Caller Information';
        const data = resolveLookupType({
            isCarrierChecked,
            isCallerChecked,
            type,
        });
        const result = await confirmModal({
            header: modalHeader,
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Cancel',
            children: <ModalBody name={name} phone={phone} type={type} />,
        });
        if (result) {
            setMutation({
                uid, groupId, docId, data, phone, type,
            });
        }
    };

    const handleRemoveClick = async () => {
        const result = await confirmModal({
            icon: <TrashIcon />,
            variant: 'destructive',
            header: 'Delete Contact',
            question: <RemoveQuestion name={name} />,
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeMutation({ uid, groupId, docId });
        }
    };

    return (
        <>
            <MenuItem
                disabled={isCarrierChecked}
                label="Add Carrier Info"
                onClick={() => handleClick('carrier')}
                icon={<StyledUserIcon as={Wireless} />}
            />
            <MenuItem
                disabled={isCallerChecked}
                label="Add Caller Info"
                onClick={() => handleClick('caller-name')}
                icon={<StyledUserIcon />}
            />
            <MenuItem
                label="Remove Contact"
                icon={<StyledUserIcon as={TrashFilled} />}
                onClick={handleRemoveClick}
            />
        </>
    );
};

const ButtonActions = ({ value, row, members }) => {
    const isCarrierChecked = !!value.carrier;
    const isCallerChecked = !!value.callerName;
    return (
        <ButtonMenu
            id="button-menu-2"
            menuAlignment="right"
            menuSize="x-small"
            icon={<More />}
            buttonVariant="base"
            className="rainbow-m-left_xx-small"
        >
            <MenuActions
                row={row}
                isCarrierChecked={isCarrierChecked}
                isCallerChecked={isCallerChecked}
                members={members}
            />
        </ButtonMenu>
    );
};

ButtonActions.propTypes = {
    value: PropTypes.object,
};

ButtonActions.defaultProps = {
    value: undefined,
};

export default ButtonActions;

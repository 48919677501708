import React from 'react';
import propTypes from 'prop-types';
import { CheckboxGroup, RenderIf } from 'react-rainbow-components';
import { useIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ImportDataInput from './importDataInput';
import { CARRIER_RATE, CALLER_RATE } from '../constants';
import Price from '../../../components/price';
import {
    Form,
    CreateButton,
    InfoContainer,
    InfoIcon,
    InfoText,
    InputName,
    Content,
    Footer,
    StyledHelpText,
} from './styled';

// eslint-disable-next-line react/prop-types
const CheckLabel = ({ title, text }) => (
    <span>
        {title}
        {' '}
        <StyledHelpText text={text} />
    </span>
);

const getNumbers = (isChecked, expression) => {
    return isChecked ? expression : 0;
};

const currencyOptions = { style: 'currency', currency: 'USD', maximumFractionDigits: 3 };

// eslint-disable-next-line import/no-mutable-exports
let NewGroupForm = (props) => {
    const {
        handleSubmit, onSubmit, contacts, checkInformation,
    } = props;
    const intl = useIntl();

    const amountOfContacts = contacts?.length;
    const isFileUpload = amountOfContacts > 0;

    const isCarrierChecked = checkInformation?.includes('carrier');
    const isCallerChecked = checkInformation?.includes('caller-name');

    const carrierRateFormatted = intl.formatNumber(CARRIER_RATE, currencyOptions);
    const callerRateFormatted = intl.formatNumber(CALLER_RATE, currencyOptions);

    const carrierCost = getNumbers(isCarrierChecked, (CARRIER_RATE * amountOfContacts));
    const callerCost = getNumbers(isCallerChecked, (CALLER_RATE * amountOfContacts));
    const carrierCostFormatted = intl.formatNumber(carrierCost, currencyOptions);
    const callerCostFormatted = intl.formatNumber(callerCost, currencyOptions);

    const total = carrierCost + callerCost;
    const showPaymentSummary = isFileUpload && (isCarrierChecked || isCallerChecked);

    const options = [
        {
            value: 'carrier', label: <CheckLabel title="Add Carrier Information" text={`This allows you to know the carriers and the type of line of the telephone numbers of the members of the group. This service will cost ${carrierRateFormatted} for each number to be analyzed.`} />, disabled: false, description: `${amountOfContacts} contacts x ${carrierRateFormatted} = ${carrierCostFormatted}`,
        },
        {
            value: 'caller-name', label: <CheckLabel title="Add Caller Information" text={`This allows you to know the caller name and the caller type of the telephone numbers of the members of the group. This service will cost ${callerRateFormatted} for each number to be analyzed.`} />, disabled: false, description: `${amountOfContacts} contacts x ${callerRateFormatted} = ${callerCostFormatted}`,
        },
    ];

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Content>
                <div>
                    <Field
                        component={InputName}
                        name="name"
                        label="Group Name"
                        required
                        placeholder="Enter the group name"
                        className="rainbow-m-top_large rainbow-m-bottom_large" />
                    <Field component={ImportDataInput} name="contacts" />
                    <RenderIf isTrue={isFileUpload}>
                        <Field component={CheckboxGroup} options={options} className="rainbow-m-top_large rainbow-m-horizontal_small" name="checkInformation" />
                    </RenderIf>
                </div>
                <div>
                    <InfoContainer>
                        <InfoIcon />
                        <InfoText>
                            We only support United States phone numbers.
                        </InfoText>
                    </InfoContainer>
                </div>
            </Content>
            <Footer showPaymentSummary={showPaymentSummary}>
                <RenderIf isTrue={showPaymentSummary}>
                    <Price label="Total Cost" value={total} precision={3} />
                </RenderIf>
                <CreateButton
                    type="submit"
                    label="Create"
                    variant="brand" />
            </Footer>
        </Form>
    );
};

NewGroupForm.propTypes = {
    handleSubmit: propTypes.func.isRequired,
    onSubmit: propTypes.func,
    contacts: propTypes.object,
    checkInformation: propTypes.array,
};

NewGroupForm.defaultProps = {
    onSubmit: () => {},
    contacts: undefined,
    checkInformation: undefined,
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'The group name is a required field.';
    }
    if (!values.contacts) {
        errors.contacts = 'There are no contacts to import. You can import contacts (name, phone) from CSV files';
    } else {
        const { success } = values.contacts.meta;
        if (success === 0) {
            errors.contacts = 'Imported contacts are not valid United States phone numbers';
        }
    }
    return errors;
};

NewGroupForm = reduxForm({
    form: 'create-group',
    touchOnBlur: true,
    validate,
})(NewGroupForm);

const selector = formValueSelector('create-group');
NewGroupForm = connect((state) => {
    const { contacts, checkInformation } = selector(state, 'contacts', 'checkInformation');
    return {
        contacts: contacts?.data,
        checkInformation,
    };
})(NewGroupForm);

export default NewGroupForm;

const statusToPropMap = {
    accepted: 'queued',
    queued: 'queued',
    sending: 'queued',
    sent: 'sent',
    received: 'received',
    delivered: 'delivered',
    undelivered: 'bounced',
    failed: 'bounced',
};

const getLabelFromStatus = (status) => statusToPropMap[status];

module.exports = getLabelFromStatus;
